import React, { useState } from "react";
import dayjs from "dayjs";
import { Box, Slider, Stack, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { MeetingDefinition } from "src/types";
import MeetingTimePicker from "./MeetingTimePicker";
import CalendarInvite from "./CalendarInvite";
import WebinarIntegrationModal from "./dialogs/WebinarIntegrationModal";
import EventTypeSelect from "./EventTypeSelect";

function WebinarEditTimeAttributesAndInvite() {
  const { control } = useFormContext<MeetingDefinition>();
  const [integrationModalOpen, setIntegrationModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <WebinarIntegrationModal
        open={integrationModalOpen}
        onClose={() => {
          setIntegrationModalOpen(false);
        }}
        onSubmit={() => {
          console.info("submit");
        }}
      />
      <Stack direction="row" width="100%">
        {/* left side */}
        <Stack direction="column" width="33%">
          <Box>
            <Box sx={{ mb: 5 }}>
              <Controller
                control={control}
                name="eventType"
                render={({ field }) => (
                  <EventTypeSelect
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>

            <Box sx={{ mb: 5 }}>
              <Typography component="span">
                To import parameters for an existing webinar from your webinar
                platform, click{" "}
                <Typography
                  onClick={() => {
                    setIntegrationModalOpen(true);
                  }}
                  component="span"
                  sx={{
                    color: "#2BAEF9",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  here
                </Typography>{" "}
                (optional)
              </Typography>
            </Box>

            <Box sx={{ mb: 6 }}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h5" fontWeight="bold">
                  Event Start Time
                </Typography>
                <Typography>What time will your webinar start?</Typography>
              </Box>
              <Controller
                control={control}
                name="startTime"
                render={({ field }) => (
                  <MeetingTimePicker
                    value={field.value || null}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>

            <Box>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h5" fontWeight="bold">
                  Event Duration
                </Typography>
                <Typography>How long should the webinar last for?</Typography>
              </Box>
              <Controller
                control={control}
                name="properties.duration"
                render={({ field }) => (
                  <>
                    <Slider
                      {...field}
                      getAriaLabel={() => "Meeting Duration"}
                      defaultValue={30}
                      valueLabelDisplay="auto"
                      step={15}
                      marks
                      min={15}
                      max={180}
                    />
                    <Typography>{field.value} Minutes</Typography>
                  </>
                )}
              />
            </Box>
          </Box>
        </Stack>

        <Box sx={{ m: 2 }} />

        {/* right side */}
        <Stack direction="column" width="67%">
          <Box sx={{ mb: 6 }}>
            <Typography variant="h5" fontWeight="bold">
              Invite Content
            </Typography>
            <Typography>
              Decide what attendees will see in the calendar event for this
              webinar
            </Typography>
          </Box>

          <CalendarInvite />
        </Stack>
      </Stack>
    </>
  );
}

export default WebinarEditTimeAttributesAndInvite;
