import React, { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Spinner } from "src/components/Spinner";
import { useUserIntegrations } from "src/queries/useUserIntegrations";
import Dialog from "./Dialog";
import {
  INTEGRATION_IMG_SRC,
  INTEGRATION_TYPES,
  INTEGRATION_TYPES_LABELS,
} from "src/integrations/props";
import Dropdown from "../Dropdown";
import IntegrationWebinarSearchInput from "../inputs/IntegrationWebinarSearchInput";
import RecentWebinars from "../RecentWebinars";
import { IntegrationWebinarEvent } from "src/types/IntegrationWebinarEvent";

function WebinarIntegrationModal({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: (value: string) => void;
  onClose: () => void;
}) {
  const { data, error, loading } = useUserIntegrations();
  const [selectedIntegration, setSelectedIntegration] = useState<string>("");
  const [selectedWebinar, setSelectedWebinar] =
    useState<IntegrationWebinarEvent | null>(null);

  const title = useMemo(() => {
    if (loading) {
      return "Select Webinar Platform";
    }

    if (error) {
      return "Select Webinar Platform";
    }

    if (!data || data.length < 1) {
      return "";
    }

    return "Select Webinar Platform";
  }, [data, error, loading]);

  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <Stack spacing={2} sx={{ pt: 1, height: 400 }}>
        {loading && <Spinner />}
        {error && (
          <Typography>
            There was a problem retrieiving your integrations, please try again
            later.
          </Typography>
        )}
        {(!data || data.length < 1) && (
          <Stack alignItems="center" spacing={5}>
            <Typography variant="h5">
              No Active Integration Detected.
            </Typography>
            <Typography variant="h5">
              Connect your webinar hosting platform to continue
            </Typography>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                padding: 2,
                gap: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Stack alignItems="center" gap={1} sx={{ cursor: "pointer" }}>
                <img
                  width={50}
                  height={50}
                  src={INTEGRATION_IMG_SRC[INTEGRATION_TYPES.DEMIO]}
                  alt="Demio logo"
                />
                <Typography>Demio</Typography>
              </Stack>
              <Stack alignItems="center" gap={1}>
                <img
                  width={50}
                  height={50}
                  src={INTEGRATION_IMG_SRC[INTEGRATION_TYPES.WEBEX]}
                  alt="Webex logo"
                />
                <Typography>Webex</Typography>
                <Typography fontSize="smaller">Coming Soon</Typography>
              </Stack>
              <Stack alignItems="center" gap={1}>
                <img
                  width={50}
                  height={50}
                  src={INTEGRATION_IMG_SRC[INTEGRATION_TYPES.MICROSOFT_TEAMS]}
                  alt="Microsoft Teams logo"
                />
                <Typography>Microsoft Teams</Typography>
                <Typography fontSize="smaller">Coming Soon</Typography>
              </Stack>
              <Stack alignItems="center" gap={1}>
                <img
                  width={50}
                  height={50}
                  src={INTEGRATION_IMG_SRC[INTEGRATION_TYPES.ZOOM]}
                  alt="Zoom logo"
                />
                <Typography>Zoom</Typography>
                <Typography fontSize="smaller">Coming Soon</Typography>
              </Stack>
            </Stack>
          </Stack>
        )}

        {data && data.length > 0 && (
          <Stack>
            <Dropdown
              label="Select Platform"
              options={data.map((integration) => ({
                label: INTEGRATION_TYPES_LABELS[integration.name],
                value: integration.name,
              }))}
              value={selectedIntegration}
              onChange={(event) => {
                setSelectedIntegration(event.target.value);
              }}
            />

            {selectedIntegration.length > 0 && (
              <Stack>
                <IntegrationWebinarSearchInput
                  integrationName={selectedIntegration}
                  value={selectedWebinar}
                  onChange={setSelectedWebinar}
                />
                <RecentWebinars integrationName={selectedIntegration} />
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
}

export default WebinarIntegrationModal;
