import React from "react";
import ImportContacts from "src/importExport/import/importContacts";
import { MeetingDefinition } from "src/types";

function WebinarEditInviteGuests({
  meetingTemplate,
}: {
  meetingTemplate?: MeetingDefinition;
}) {
  return <ImportContacts preSelectedMeetingTemplate={meetingTemplate} />;
}

export default WebinarEditInviteGuests;
