import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { MeetingDefinition } from "src/types";
import Dropdown from "./Dropdown";

function SendThisMessage({ emailIndex }: { emailIndex: number }) {
  const {
    control,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          marginBottom: "auto",
          marginTop: "auto",
        }}
      >
        Send this message
      </Typography>{" "}
      <Controller
        control={control}
        name={`emailTemplates.${emailIndex}.webinarTemplateConfig.num`}
        rules={{
          required: {
            value: true,
            message: "Required",
          },
          validate: (value: number | string) => {
            if (!Number.isInteger(value)) {
              value = parseInt(value as string);
            }

            return Number.isInteger(value) || "Required";
          },
        }}
        render={({ field }) => (
          <Stack>
            <TextField
              sx={{
                width: "100px",
                marginLeft: "15px",
                marginRight: "10px",
              }}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              value={field.value}
              onChange={field.onChange}
            />
            {errors.emailTemplates?.[emailIndex]?.webinarTemplateConfig
              ?.num && (
              <Box sx={{ color: "red", mb: 3 }}>
                {
                  errors.emailTemplates[emailIndex].webinarTemplateConfig.num
                    .message
                }
              </Box>
            )}
          </Stack>
        )}
      />
      <Controller
        control={control}
        name={`emailTemplates.${emailIndex}.webinarTemplateConfig.units`}
        rules={{
          required: {
            value: true,
            message: "Required",
          },
          validate: (value) => value.length > 0 || "Required",
        }}
        render={({ field }) => (
          <Box
            sx={{
              width: "150px",
              marginRight: "15px",
            }}
          >
            <Dropdown
              value={field.value}
              onChange={field.onChange}
              options={[
                { value: "minutes", label: "Minutes" },
                { value: "hours", label: "Hours" },
                { value: "days", label: "Days" },
                { value: "weeks", label: "Weeks" },
              ]}
            />
            {errors.emailTemplates?.[emailIndex]?.webinarTemplateConfig
              ?.units && (
              <Box sx={{ color: "red", mb: 3 }}>
                {
                  errors.emailTemplates[emailIndex].webinarTemplateConfig.units
                    .message
                }
              </Box>
            )}
          </Box>
        )}
      />
      <Typography
        sx={{
          marginBottom: "auto",
          marginTop: "auto",
        }}
      >
        before the event starts
      </Typography>
    </Stack>
  );
}

export default SendThisMessage;
