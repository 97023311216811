import React from "react";

import { Box, Stack, TextField } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Dayjs } from "dayjs";

function MeetingTimePicker({
  value,
  onChange,
}: {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
}) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        start: "Start",
        end: "End",
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center">
          <DatePicker
            label="Date"
            disablePast
            value={value}
            onChange={(v) => {
              if (v && !v.isValid()) {
                onChange(value);
              } else {
                onChange(v);
              }
            }}
            renderInput={(props) => <TextField {...props} />}
          />
          <Box sx={{ mx: 2 }}> at </Box>
          <TimePicker
            disableOpenPicker
            label="Time"
            value={value}
            onChange={(v) => {
              if (v && !v.isValid()) {
                onChange(value);
              } else {
                onChange(v);
              }
            }}
            renderInput={(props) => <TextField {...props} />}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}

export default MeetingTimePicker;
