import React, { useState } from "react";
import {
  Container,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { AccessTime, Person } from "@mui/icons-material";
import { Link as RouterLink, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import dayjs from "dayjs";

import { useEventDetails } from "src/queries/useEventDetails";
import Loading from "src/componentsV2/Loading";
import ErrorPage from "src/pages/ErrorPage";
import { GuestStatusIndicator } from "src/pages/meetings";
import { Table } from "src/componentsV2/Table";

type EventDetailsPageTabs = "All" | "No Response" | "Accepted" | "Attended";

function EventDetails() {
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState<EventDetailsPageTabs>("All");

  const { data, loading, error } = useEventDetails(Number.parseInt(id));

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="xl" disableGutters sx={{ p: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ pb: 1 }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link component={RouterLink} to="/events">
            Events
          </Link>
          <Typography>{id}</Typography>
        </Breadcrumbs>
      </Stack>

      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Stack spacing={1}>
            {data.meetingType.name ? (
              <Typography
                variant="h4"
                sx={{ color: "primary.dark", fontWeight: "bold" }}
              >
                {data.meetingType.name}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                sx={{
                  color: "primary.dark",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                [EVENT MISSING]
              </Typography>
            )}

            <Stack direction="row" spacing={2}>
              <Stack direction="row" spacing={1}>
                <Person />
                <Typography>
                  <Typography fontWeight="bold" component="span">
                    Host
                  </Typography>
                  {data.host ? (
                    <Stack>
                      <Typography>
                        {data.host.firstName} {data.host.lastName}
                      </Typography>
                      <Typography color="primary">{data.host.email}</Typography>
                    </Stack>
                  ) : (
                    <Typography>No Host</Typography>
                  )}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1}>
                <AccessTime />
                <Typography>
                  <Typography fontWeight="bold" component="span">
                    Meeting Time
                  </Typography>
                  {data.startTime ? (
                    <Stack>
                      <Typography>
                        {dayjs(data.startTime).format("M/D/YYYY")}
                      </Typography>
                      <Typography>
                        {dayjs(data.startTime).format("h:mm A z")}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography>Not yet scheduled</Typography>
                  )}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1}>
                <Stack sx={{ width: "100%", maxWidth: "350px" }} spacing={1}>
                  <Typography fontWeight="bold" component="span">
                    Template Type: Kronologic Webinar
                  </Typography>
                  <Typography>
                    <Typography fontWeight="bold" component="span">
                      Meeting Location: TODO
                    </Typography>{" "}
                    {/* {data.meetingType?.invite?.location ? (
                      <a href={data.meetingType?.invite?.location}>
                        Link to meeting
                      </a>
                    ) : (
                      "None"
                    )} */}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Grid container>
              <Grid item xs={9}>
                <Stack
                  sx={{
                    borderStyle: "solid",
                    borderRadius: "5px",
                    borderColor: "black",
                    borderWidth: "1px",
                    width: "100%",
                    minHeight: "600px",
                    p: 2,
                    gap: 2,
                  }}
                >
                  <Tabs
                    value={tab}
                    sx={{
                      textDecoration: 0,
                      borderBottom: "1px solid #CDD6DD",
                    }}
                    TabIndicatorProps={{
                      style: { backgroundColor: "transparent" },
                    }}
                    onChange={(_event, value: EventDetailsPageTabs) => {
                      switch (value) {
                        case "All":
                          break;
                        case "No Response":
                          break;
                        case "Accepted":
                          break;
                        case "Attended":
                          break;
                      }
                      setTab(value);
                    }}
                  >
                    <Tab
                      label={`Everyone ()`}
                      value="everyone"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`Accepted ()`}
                      value="accepted"
                      sx={{ textTransform: "none" }}
                    />{" "}
                    <Tab
                      label={`Declined ()`}
                      value="declined"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`No Response ()`}
                      value="no_response"
                      sx={{ textTransform: "none" }}
                    />
                  </Tabs>
                  {data.guests && data.guests.length > 0 && (
                    <Table
                      loading={loading}
                      data={data.guests}
                      columns={[
                        {
                          id: "guestInfo",
                          label: "Guest",
                          component: (row) => (
                            <Stack>
                              <Typography variant="caption">
                                {row.firstName} {row.lastName}
                              </Typography>
                              <Typography variant="caption">
                                {row.email}
                              </Typography>
                            </Stack>
                          ),
                        },
                        {
                          id: "status",
                          label: "Guest Status",
                          component: (row) => (
                            <GuestStatusIndicator status={row.status} />
                          ),
                        },
                      ]}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}

export default EventDetails;
