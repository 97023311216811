import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useDebounce } from "src/hooks";
import { useIntegrationWebinarSearch } from "src/queries/useIntegrationWebinarSearch";
import { INTEGRATION_TYPES_LABELS } from "src/integrations/props";
import { IntegrationWebinarEvent } from "src/types/IntegrationWebinarEvent";

function IntegrationWebinarSearchInput({
  integrationName,
  value,
  onChange,
}: {
  integrationName: string;
  value?: IntegrationWebinarEvent | null;
  onChange: (value: IntegrationWebinarEvent | null) => void;
}) {
  const [search, setSearch] = useState<string>("");

  const s = useDebounce(search, 750);

  const { data, error, loading } = useIntegrationWebinarSearch(
    s,
    integrationName,
  );

  const updateValue = ({ value }: { value: IntegrationWebinarEvent }) => {
    onChange(value);
  };

  return (
    <Autocomplete
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Search your ${INTEGRATION_TYPES_LABELS[integrationName]} webinars`}
        />
      )}
      value={value ? { label: value.name, value } : undefined}
      onChange={(_, newValue) => {
        if (newValue) {
          updateValue(newValue);
        }
      }}
      inputValue={search}
      onInputChange={(event, newValue) => {
        setSearch(newValue);
      }}
      options={
        data?.map((webinar) => ({
          label: webinar.name,
          value: webinar,
        })) || []
      }
      getOptionLabel={(webinar) => webinar.label}
    />
  );
}

export default IntegrationWebinarSearchInput;
