import React from "react";
import {
  ToggleButtonGroup,
  Stack,
  Typography,
  ToggleButton,
} from "@mui/material";
import { EventType } from "src/types/EventType";

function customStyle(currVal: EventType | undefined, buttonVal: EventType) {
  return {
    borderRadius: "20px !important",
    border: "2px solid #2BAEF9 !important",
    backgroundColor:
      currVal === buttonVal ? "#2BAEF9 !important" : "white !important",
    color: currVal === buttonVal ? "white !important" : "#2BAEF9 !important",
    height: "25px",
    textTransform: "none",
    width: "150px",
  };
}

function EventTypeSelect({
  value,
  onChange,
}: {
  value: EventType | undefined;
  onChange: (eventType: EventType) => void;
}) {
  return (
    <Stack>
      <Typography>This event will occur (select one):</Typography>
      <ToggleButtonGroup
        exclusive
        value={value}
        onChange={(event, value) => {
          onChange(value);
        }}
        sx={{
          gap: 2,
          marginTop: "10px",
        }}
      >
        <ToggleButton value="In Person" sx={customStyle(value, "In Person")}>
          In Person
        </ToggleButton>
        <ToggleButton value="Online" sx={customStyle(value, "Online")}>
          Online
        </ToggleButton>
        <ToggleButton
          value="As a Webinar"
          sx={customStyle(value, "As a Webinar")}
        >
          As a Webinar
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

export default EventTypeSelect;
