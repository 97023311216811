import React, { useState } from "react";
import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Spinner } from "src/components/Spinner";
import { useIntegrationRecentWebinars } from "src/queries/useIntegrationRecentWebinars";

function RecentWebinars({ integrationName }: { integrationName: string }) {
  const { data, error, loading } =
    useIntegrationRecentWebinars(integrationName);
  const [selected, setSelected] = useState<number | null>(null);

  return (
    <>
      <Typography variant="h5">Recent</Typography>
      {error && (
        <Typography>
          There was a problem fetching recent events. Please try again later.
        </Typography>
      )}
      {loading && <Spinner />}
      {(!data || data.length < 1) && (
        <Typography>No recent events found.</Typography>
      )}
      {data && data.length > 0 && (
        <List sx={{ borderRadius: "5px" }}>
          {data.map((recentWebinar, idx) => (
            <React.Fragment key={`recent-webinar-${idx + 1}`}>
              {idx > 0 && <Divider />}
              <ListItemButton
                key={`recent-webinar-${idx + 1}`}
                selected={selected !== null && selected == recentWebinar.id}
                onClick={() => {
                  setSelected(recentWebinar.id);
                }}
              >
                <ListItemText primary={recentWebinar.name} />
              </ListItemButton>
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
}

export default RecentWebinars;
