import {
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useOpenAiIntegration } from "src/features";
import { getDynamicVariableMappings } from "src/meetingTypes/useDynamicVariableMappings";
import { MeetingDefinition } from "src/types";
import { TipTapPreview } from "./RichText";
import TemplateAIModal from "./TemplateAIModal";
import SecondaryButton from "./buttons/SecondaryButton";
import TemplateInputWithMergeFields from "./inputs/TemplateInputWithMergeFields";
import { EmailEditor, cleanRichTextHTML } from "./inputs";

type EmailTemplateProps = {
  emailIndex: number;
};

function EmailTemplate(props: EmailTemplateProps) {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");
  const [aiModalOpen, setAIModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const openAIEnabled = useOpenAiIntegration();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues(`emailTemplates.${props.emailIndex}.title`),
  );
  const previewBody = getDynamicVariableMappings(
    getValues(`emailTemplates.${props.emailIndex}.body`),
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 5 }}>
        {selectedTab === "draft" && (
          <>
            <Controller
              control={control}
              name={`emailTemplates.${props.emailIndex}.title`}
              rules={{
                required: {
                  value: true,
                  message: "Email title is required",
                },
              }}
              render={({ field }) => (
                <TemplateInputWithMergeFields
                  label="Email Title"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            {errors.emailTemplates?.[props.emailIndex]?.title && (
              <Box sx={{ color: "red", mb: 3 }}>
                {errors.emailTemplates?.[props.emailIndex]?.title?.message}
              </Box>
            )}

            <Controller
              control={control}
              name={`emailTemplates.${props.emailIndex}.body`}
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Email Body is required",
              }}
              render={({ field }) => (
                <>
                  <TemplateAIModal
                    open={aiModalOpen}
                    onClose={() => {
                      setAIModalOpen(false);
                    }}
                    onAIResponse={(text: string) => {
                      const cleaned = cleanRichTextHTML(text);
                      field.onChange(cleaned);
                    }}
                  />

                  <Box sx={{ mb: 2 }}>
                    <EmailEditor
                      label="Email body"
                      value={field.value ?? ""}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      withMergeFields
                    />
                  </Box>
                </>
              )}
            />
            {errors.emailTemplates?.[props.emailIndex]?.body && (
              <Box sx={{ color: "red", mb: 3 }}>
                {errors.emailTemplates?.[props.emailIndex]?.body?.message}
              </Box>
            )}
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="normal">
              <Typography component="span" fontWeight="bold" fontSize="inherit">
                Subject:
              </Typography>{" "}
              {previewTitle}
            </Typography>
            {/* Only show this if "Kronologic Email" is selected */}
            {getValues("inviteStyle") === "custom" && (
              <Stack
                sx={{
                  borderRadius: "5px",
                  border: "1px solid #E1E6EB",
                  flexDirection: "row",
                  mb: 3,
                }}
              >
                <Stack
                  sx={{
                    py: 9,
                    px: 4,
                    borderRight: "1px solid #E1E6EB",
                    backgroundColor: "#E1E6EB",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper elevation={1}>
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "5px 5px 0 0",
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "uppercase",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        MONTH
                      </Typography>
                    </Box>
                    <Stack
                      sx={{
                        backgroundColor: "white",
                        py: 1,
                        px: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0 0 5px 5px",
                      }}
                    >
                      <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                        ##
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        00:00 AM PST
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: theme.palette.primary.dark,
                        }}
                      >
                        Day
                      </Typography>
                    </Stack>
                  </Paper>
                </Stack>
                <Box sx={{ py: 3, px: 4 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">
                      Calendar Invite Subject Line
                    </Typography>
                    <Typography>## Minutes</Typography>
                    <Typography>CST</Typography>
                  </Box>

                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6">
                      [DOW] [Month] [##] [YYYY] 00:00am CST{" "}
                    </Typography>
                    <Typography>
                      Location:
                      https://us02web.zoom.us/j/sdfalkjwerlkjwerlkjlkjlkj
                    </Typography>
                  </Box>

                  <Stack sx={{ mb: 1, flexDirection: "row", gap: 1 }}>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        color: "#014A74",
                        border: "1px solid #014A74",
                        p: 1,
                        fontSize: "12px",
                      }}
                    >
                      Accept
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        color: "#014A74",
                        border: "1px solid #014A74",
                        p: 1,
                        fontSize: "12px",
                      }}
                    >
                      Decline
                    </Box>
                  </Stack>

                  <Typography
                    component="span"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    See more available times
                  </Typography>
                </Box>
              </Stack>
            )}
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
        {openAIEnabled && (
          <SecondaryButton
            onClick={() => {
              setAIModalOpen(true);
            }}
          >
            Use AI to write your email
          </SecondaryButton>
        )}
      </Box>
    </>
  );
}

export default EmailTemplate;
