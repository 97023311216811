import React from "react";
import { Box, Typography } from "@mui/material";

import { EmailTemplatesAccordion } from "./TemplateEditInviteAndEmail";

function WebinarEditPromote() {
  return (
    <Box>
      <Typography variant="h5" fontWeight="bold">
        Email
      </Typography>
      <Typography sx={{ mb: 3 }}>
        Because you have selected{" "}
        <Typography fontWeight="bold" component="span">
          Kronologic Webinar
        </Typography>{" "}
        this will be sent to the customer first, the calendar invite will be
        sent once they have accepted
      </Typography>

      <EmailTemplatesAccordion allowCustomSendTime />
    </Box>
  );
}

export default WebinarEditPromote;
