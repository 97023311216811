import { Delete, Error } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { isLinkFirst } from "src/meetingTypes/invite/props";
import { getDynamicVariableMappings } from "src/meetingTypes/useDynamicVariableMappings";
import { MeetingDefinition } from "src/types";
import { buildRange } from "src/utils/helpers";
import { TipTapPreview } from "./RichText";
import SecondaryButton from "./buttons/SecondaryButton";
import TemplateInputWithMergeFields from "./inputs/TemplateInputWithMergeFields";
import { EmailEditor } from "./inputs";
import CalendarInvite from "./CalendarInvite";
import EmailTemplate from "./EmailTemplate";
import SendThisMessage from "./SendThisMessage";

export const TemplateEditInviteAndEmail = () => {
  const { control, register, setValue, getValues, watch, clearErrors } =
    useFormContext<MeetingDefinition>();

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Invite & Email Content
        </Typography>
        <Typography>
          {!isLinkFirst(getValues("inviteStyle"))
            ? `Enter the copy for your calendar invite and email. Set any additional attempts, decline messages, and unsubscribe settings.`
            : `Enter the copy for your calendar invite and email.`}
        </Typography>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Typography variant="h5" fontWeight="bold">
          Calendar Invite
        </Typography>

        {getValues("inviteStyle") === "calendar_first" && (
          <Typography sx={{ mb: 3 }}>
            Because you have selected{" "}
            <Typography fontWeight="bold" component="span">
              Kronologic Invite
            </Typography>
            , this will be sent at the same time as the calendar invite.
          </Typography>
        )}

        {getValues("inviteStyle") === "custom" && (
          <Typography sx={{ mb: 3 }}>
            Because you have selected{" "}
            <Typography fontWeight="bold" component="span">
              Kronologic Email
            </Typography>{" "}
            this will only show on the guest's calendar once they have accepted
            via the first email.
          </Typography>
        )}

        <CalendarInvite />
        <Divider sx={{ my: 5 }} />
      </Box>

      <Typography variant="h5" fontWeight="bold">
        Email
      </Typography>

      {getValues("inviteStyle") === "calendar_first" && (
        <Typography sx={{ mb: 3 }}>
          Because you have selected{" "}
          <Typography fontWeight="bold" component="span">
            Kronologic Invite
          </Typography>
          , this will be sent at the same time as the calendar invite.
        </Typography>
      )}

      {getValues("inviteStyle") === "custom" && (
        <Typography sx={{ mb: 3 }}>
          Because you have selected{" "}
          <Typography fontWeight="bold" component="span">
            Kronologic Email
          </Typography>{" "}
          this will be sent to the customer first, the calendar invite will be
          sent once they have accepted the meeting request
        </Typography>
      )}

      <Box sx={{ mb: 5 }}>
        {!isLinkFirst(getValues("inviteStyle")) && <EmailTemplatesAccordion />}

        {isLinkFirst(getValues("inviteStyle")) && (
          <Box sx={{ mb: 5 }}>
            <EmailTemplate emailIndex={0} />
          </Box>
        )}
      </Box>

      {!isLinkFirst(getValues("inviteStyle")) && (
        <Box sx={{ mb: 5 }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              checked={getValues("properties.cleanDeclineRule.enabled")}
              onChange={(event) => {
                clearErrors("properties.cleanDeclineRule");

                if (!event.target.checked) {
                  return setValue("properties.cleanDeclineRule.enabled", false);
                }

                setValue("properties.cleanDeclineRule.enabled", true);
              }}
            />
            <Typography variant="h5" fontWeight="bold">
              Decline Message
            </Typography>
          </Stack>
          <Typography sx={{ mb: 2 }}>
            If enabled, the guest will receive an additional message after
            declining the meeting
          </Typography>

          {getValues("properties.cleanDeclineRule.enabled") && <DeclineEmail />}
        </Box>
      )}

      <Box sx={{ mb: 5 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <Switch
            checked={getValues("properties.meetingReminder.enabled")}
            onChange={(event) => {
              clearErrors("properties.meetingReminder");

              // do NOT null out the meeting reminder, we can keep what the user has saved
              if (!event.target.checked) {
                return setValue("properties.meetingReminder", {
                  ...getValues("properties.meetingReminder"),
                  enabled: false,
                });
              }

              setValue("properties.meetingReminder", {
                ...getValues("properties.meetingReminder"),
                enabled: true,
              });
            }}
          />
          <Typography variant="h5" fontWeight="bold">
            Meeting Reminder
          </Typography>
        </Stack>
        <Typography sx={{ mb: 2 }}>
          If enabled, the guest will receive a reminder email at the configured
          interval before the start of the meeting
        </Typography>

        {getValues("properties.meetingReminder.enabled") && (
          <>
            <ReminderOptions />
            <ReminderEmail />
          </>
        )}
      </Box>

      {!isLinkFirst(getValues("inviteStyle")) && (
        <Box sx={{ mb: 5 }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="unsubscribeTemplate.enabled"
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              Unsubscribe Message
            </Typography>
          </Stack>
          <Typography sx={{ mb: 2 }}>
            If enabled, all emails from this template will include an
            unsubscribe link
          </Typography>

          {watch("unsubscribeTemplate.enabled") && (
            <Stack sx={{ gap: 2, mt: 2 }}>
              <Box>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  What should the body of the unsubscribe clause say
                </Typography>
                <Typography>
                  Use {"{{link}}"} to signify where you'd like to hyperlink text
                  to appear
                </Typography>
              </Box>
              <TextField
                {...register("unsubscribeTemplate.body")}
                size="small"
                placeholder={`If you do not wish to receive meeting requests from Kronologic, {{link}}`}
                sx={{ width: "100%", mb: 2 }}
              />
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                What should the hyperlink say?
              </Typography>
              <TextField
                {...register("unsubscribeTemplate.hyperlinkText")}
                size="small"
                placeholder="Click Here"
                sx={{ width: "100%", mb: 2 }}
              />
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export const EmailTemplatesAccordion = ({
  allowCustomSendTime = false,
}: {
  allowCustomSendTime?: boolean;
}) => {
  const [expanded, setExpanded] = useState("email-invite-0");

  const {
    control,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();
  const {
    fields: emailTemplates,
    append,
    remove,
  } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: "emailTemplates", // unique name for your Field Array
  });

  return (
    <>
      <Box sx={{ mb: 5 }}>
        {emailTemplates.map((emailItem, index) => (
          <Accordion
            key={emailItem.id}
            expanded={expanded === `email-invite-${index}`}
            onChange={() => {
              if (expanded === `email-invite-${index}`) {
                return setExpanded("");
              }
              setExpanded(`email-invite-${index}`);
            }}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls={`email-invite-${index}-content`}
              id={`email-invite-${index}-header`}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {errors.emailTemplates?.[index] && (
                    <Error sx={{ color: "red" }} />
                  )}
                  <Typography
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                      color: errors.emailTemplates?.[index] ? "red" : "inherit",
                    }}
                  >
                    Email Attempt #{index + 1}
                  </Typography>
                </Stack>

                {index > 0 && (
                  <IconButton sx={{ ml: "auto" }} onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                )}
              </Stack>
            </AccordionSummary>

            <Stack
              sx={{
                color: "black",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {allowCustomSendTime && <SendThisMessage emailIndex={index} />}
            </Stack>

            <AccordionDetails>
              <EmailTemplate emailIndex={index} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {emailTemplates.length < 3 && (
        <>
          <SecondaryButton
            onClick={() =>
              append({
                body: "",
                order: emailTemplates.length + 1,
                title: "",
              })
            }
          >
            Add Email Template
          </SecondaryButton>
        </>
      )}
    </>
  );
};

const DeclineEmail = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");

  const { control, getValues } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("properties.cleanDeclineRule.action.meta.title"),
  );
  const previewBody = getDynamicVariableMappings(
    getValues("properties.cleanDeclineRule.action.meta.body"),
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="Decline Email Tabs"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 5 }}>
        {selectedTab === "draft" && (
          <>
            <Controller
              control={control}
              name={"properties.cleanDeclineRule.action.meta.title"}
              rules={{
                required: "Decline Email Title is required",
                minLength: {
                  value: 5,
                  message: "Decline Email Title must be at least 5 characters.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TemplateInputWithMergeFields
                    label="Decline Email Title"
                    value={field.value}
                    onChange={field.onChange}
                  />
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error?.message}</Box>
                  )}
                </>
              )}
            />
            <Controller
              control={control}
              name={"properties.cleanDeclineRule.action.meta.body"}
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Decline Email Body is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Box sx={{ mb: 3 }}>
                    <EmailEditor
                      label="Decline Email Body"
                      value={field.value ?? ""}
                      onChange={(value) => field.onChange(value)}
                      withMergeFields
                    />
                  </Box>
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error.message}</Box>
                  )}
                </>
              )}
            />
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="bold">
              {previewTitle}
            </Typography>
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const ReminderEmail = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");

  const { control, getValues } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("properties.meetingReminder.title"),
  );
  const previewBody = getDynamicVariableMappings(
    getValues("properties.meetingReminder.body"),
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="Meeting Reminder Email Tabs"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 1 }}>
        {selectedTab === "draft" && (
          <>
            <Controller
              control={control}
              name="properties.meetingReminder.title"
              rules={{
                required: "Reminder Email Title is required",
                minLength: {
                  value: 5,
                  message:
                    "Reminder Email Title must be at least 5 characters.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TemplateInputWithMergeFields
                    label="Reminder Email Title"
                    value={field.value}
                    onChange={field.onChange}
                  />
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error?.message}</Box>
                  )}
                </>
              )}
            />
            <Controller
              control={control}
              name="properties.meetingReminder.body"
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Reminder Email Body is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Box sx={{ mb: 3 }}>
                    <EmailEditor
                      label="Reminder Email Body"
                      value={field.value ?? ""}
                      onChange={(value) => field.onChange(value)}
                      withMergeFields
                    />
                  </Box>
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error.message}</Box>
                  )}
                </>
              )}
            />
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="bold">
              {previewTitle}
            </Typography>
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const ReminderOptions = () => {
  const { control } = useFormContext<MeetingDefinition>();

  const dayOptions: number[] = buildRange([0, 10, 1]);
  const hourOptions: number[] = buildRange([0, 23, 1]);
  const minuteOptions: number[] = buildRange([0, 45, 15]);

  return (
    <>
      <Typography
        sx={{
          marginBottom: 2,
        }}
      >
        Meeting reminders will not be sent if either the guest has accepted the
        meeting on day of the meeting or if the guest would have received a
        reminder email within 5 hours of accepting the meeting
      </Typography>

      <Stack direction="row" sx={{ width: "25%" }}>
        <Controller
          control={control}
          name="properties.meetingReminder.days"
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel id="reminder-days-select">Days</InputLabel>
              <Select
                sx={{ marginRight: "5px", width: "100%" }}
                label="Days"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {dayOptions.map((day) => (
                  <MenuItem key={`day-${day}`} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="properties.meetingReminder.hours"
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel id="reminder-hours-select">Hours</InputLabel>
              <Select
                sx={{ width: "100%" }}
                label="Hours"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {hourOptions.map((hour) => (
                  <MenuItem key={`hour-${hour}`} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="properties.meetingReminder.minutes"
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="reminder-minutes-select">Minutes</InputLabel>
              <Select
                sx={{ width: "100%" }}
                label="Minutes"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {minuteOptions.map((minutes) => (
                  <MenuItem key={`minutes-${minutes}`} value={minutes}>
                    {minutes}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Stack>
    </>
  );
};
